<template>
  <yb-highlight language="sql" :content="queryText" :wrap="wrap" class="overflow-hidden whitespace-pre-wrap max-h-8 w-full text-sm" />
</template>

<script>

export default {
  computed: {
    queryText() {
      return this.params.value
    },
    wrap() {
      if (typeof this.params.wrap !== 'undefined') {
        return this.params.wrap
      } else {
        return false
      }
    }
  }
}
</script>
