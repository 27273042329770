<template>
  <yb-icon v-show="!!params && !!params.value" v-tooltip="tooltip" :icon="icon" class="h-4 w-4" :class="iconClass" />
</template>

<script>
export default {
  computed: {
    icon() {
      return this.params.value === '00000' ? 'check' : 'delete-circle'
    },
    iconClass() {
      return this.params.value === '00000' ? '' : 'yb-error-text'
    },
    tooltip() {
      const { tooltip } = this.params
      if (!!tooltip && typeof this.params.data === 'object' && this.params.data[tooltip]) {
        return `${this.params.value}: ${this.params.data[tooltip]}`
      } else {
        return 'OK'
      }
    }
  }
}
</script>
