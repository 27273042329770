<template>
  <div v-tooltip="tooltip" style="min-width: 100px;" class="flex flex-row flex-nowrap items-center h-full">
    <div class="border rounded h-3 border-yb-brand-primary w-full mr-4 flex-grow">
      <div
        class="bg-yb-brand-primary h-full rounded-l rounded-r"
        :style="{ width: `${percent}%` }"
      />
    </div>
    <div class="text-right text-xs" style="min-width: 30px" v-text="`${percent}%`" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      percent: 0,
      tooltip: null,
      junk: 'trunk'
    }
  },
  mounted() {
    this.percent = Math.round(this.params.value * 100)
    if (Number.isNaN(this.percent)) { this.percent = 0 } else if (this.percent > 100) { this.percent = 100 }

    if (this.params.tooltip) {
      if (typeof this.params.tooltip === 'function') {
        this.tooltip = this.params.tooltip(this.params.data)
      } else {
        this.tooltip = String(this.params.tooltip)
      }
    }
  }
}
</script>
