<template>
  <div class="h-full w-full flex items-center">
    <p v-for="role of params.value" :key="role.id" class="px-3 py-0.5 text-md border mr-2 rounded-full">
      {{ createLabel(role.name) }}
    </p>
  </div>
</template>

<script>
export default {
  methods: {
    createLabel(name) {
      const map = {
        consumer: 'Consumer',
        consumeradmin: 'Consumer Admin'
      }
      return map[name] ?? name
    }
  }
}
</script>
