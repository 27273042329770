<template>
  <div class="flex items-center h-full">
    <yb-icon :icon="getOpIcon(description)" class="w-4 h-4 mx-1 inline-block" :class="getStatusColorClass(description)" />
    <p class="text-sm">
      {{ description }}
    </p>
  </div>
</template>

<script>

export default {
  computed: {
    description() {
      return this.params.value
    }
  },
  methods: {
    getOpIcon,
    getStatusColorClass
  }
}
</script>
