import { defineComponent } from 'vue'

import YbGridQueryTextCellRendererComponent from './grid/YbGridQueryTextCellRendererComponent.vue'
import YbGridPercentBar from './grid/YbGridPercentBar.vue'
import YbGridStatusComponent from './grid/YbGridStatusComponent.vue'
import YbGridErrorCodeComponent from './grid/YbGridErrorCodeComponent.vue'
import YbGridStorageReport from './grid/YbGridStorageReport.vue'
import YbGridUserRoles from './grid/YbGridUserRoles.vue'
import YbGridClusterStatus from './grid/YbGridClusterStatus.vue'

export * from 'shared/src/components/grid/YbGridCellRenderers'

// Make the component constructors for our cell renderers.
const YbGridQueryTextCellRenderer = defineComponent(YbGridQueryTextCellRendererComponent)
export { YbGridQueryTextCellRenderer }
const YbGridPercentBarCellRenderer = defineComponent(YbGridPercentBar)
export { YbGridPercentBarCellRenderer }
const YbGridStatusCellRenderer = defineComponent(YbGridStatusComponent)
export { YbGridStatusCellRenderer }
const YbGridErrorCodeCellRenderer = defineComponent(YbGridErrorCodeComponent)
export { YbGridErrorCodeCellRenderer }
const YbGridStorageReportCellRenderer = defineComponent(YbGridStorageReport)
export { YbGridStorageReportCellRenderer }
const YbGridUserRolesCellRenderer = defineComponent(YbGridUserRoles)
export { YbGridUserRolesCellRenderer }
const YbGridClusterStatusCellRenderer = defineComponent(YbGridClusterStatus)
export { YbGridClusterStatusCellRenderer }
